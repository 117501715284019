import React, { Component } from 'react'
import loader from "../loader.gif"

export class Preloder extends Component {
  render() {
    return (
        <div className='text-center'>
         <img src={loader} alt='preloder' style={{width:'5%'}}/>
        </div>
      
    )
  }
}

export default Preloder