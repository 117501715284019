import React, { Component } from 'react'
import Newsitem from './Newsitem'
import Preloder from './Preloder';
import InfiniteScroll from 'react-infinite-scroll-component';

export class Newscoponent extends Component {
  
 results=[];
 constructor(){
  super();
     this.state={
         results:this.results,
         loading:false,
         page:0,
         totalResults:null,
         

     }
 }

  componentDidMount=async(props)=>{
   this.setState({loading:true});
   this.props.setProgress(10);
  let data=await fetch(`https://newsdata.io/api/1/news?apikey=pub_6431c2f1ebc4b33f20af03a8640a35d6822c&country=in&category=${this.props.category}&page=${this.state.page}`);
    console.log(data);
    //  let data=await fetch(`https://newsapi.org/v2/top-headlines?country=in&category=${this.props.category}&apiKey=3e1ac91c09b648b6bfaf7914ede144c0&pageSize=10&page=${this.state.page}`);
    this.props.setProgress(70); 
    let dataObject=await data.json();
     console.log(dataObject)
     this.props.setProgress(90);
     this.setState({results:dataObject.results,totalResults:dataObject.totalResults,loading:false});
     this.props.setProgress(100);
     console.log(this.props.category);
    
         
     
 }


//    previousBtn=async()=>{
//    console.log('previous');
   
//    this.setState({loading:true});
//    let dataPrevious=await fetch(`https://newsdata.io/api/1/news?apikey=pub_6431c2f1ebc4b33f20af03a8640a35d6822c&country=in&category=${this.props.category}&page=${this.state.nextPage-1}`)
//    console.log(dataPrevious);
//    let dataObject=await dataPrevious.json();
//    console.log(dataObject);

   
//    this.setState({articles:dataObject.results,page:this.state.nextPage-1,loading:false});
//  }


//  nextBtn=async()=>{
//     console.log('next');
//     this.setState({loading:true});
    
//     if(this.state.page>Math.ceil(this.state.totalArticles/10)){
//     }
//     else{
//       let dataNext=await fetch(`https://newsdata.io/api/1/news?apikey=pub_6431c2f1ebc4b33f20af03a8640a35d6822c&country=in&category=${this.props.category}&page=${this.state.page+1}`)
//       console.log(dataNext);
//       let dataObject=await dataNext.json();
   
//       console.log(dataObject);
//       this.setState({articles:dataObject.results,nextPage:this.state.nextPage+1,loading:false});
//     }
     
//     }

     fetchMoreData= async ()=>{
      
     
      let data=await fetch(`https://newsdata.io/api/1/news?apikey=pub_6431c2f1ebc4b33f20af03a8640a35d6822c&country=in&category=${this.props.category}&page=${this.state.page+1}`);
    //  let data=await fetch(`https://newsapi.org/v2/top-headlines?country=in&category=${this.props.category}&apiKey=3e1ac91c09b648b6bfaf7914ede144c0&pageSize=10&page=${this.state.page}`);
    console.log(data);
     let dataObject=await data.json();
     console.log(dataObject)
     
     this.setState({results:this.state.results.concat(dataObject.results),loading:false,page:this.state.page+1});
     console.log(this.props.category);
      


    }    
    
 
 
  render() { 
    
    return (
        <div className='container my-3'>
        <h2>Top Headlines</h2>
          <InfiniteScroll
          dataLength={this.state.results.length}
          next={this.fetchMoreData}
          hasMore={true}
          loader={<Preloder/>}
        >
         <div className='row ' style={{width:"100%"}}>
      {this.state.results.map((element)=>{
         
          return  <div className='col-md-3 my-3 ' key={element.link} style={{boxSizing:'border-box'}}>
          <Newsitem  imageUrl={element.image_url} url={element.link} title={element.title} description={element.description} author={element.source_id} date={element.pubDate}/>
         </div>
      })}
        
      </div>
      </InfiniteScroll>
        
        


        
        </div>
        
        
       
    )
  }
}

export default Newscoponent