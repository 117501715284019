import React, { Component } from 'react'

import greyImage from '../grey.png'

export class Newsitem extends Component {
    
  render() {
    const {imageUrl,url,title,discription,author,date}=this.props;
    return (
        <>
       

        <div className="card  " style={{width:"18rem",margin:"auto"}}>
        <div style={{display:'flex',justifyContent:'flex-end',position:'absolute',right:'0'}}>
        <span className="badge rounded-pill bg-danger" >{author}</span>
        </div>
        
            <img src={imageUrl===null?greyImage:imageUrl} className="card-img-top" style={{width:"18em",height:"12em",objectFit:"cover",}} alt={url} />
            <div className="card-body">
               <h5 className="card-title my-1">{title.slice(0,40)}...</h5>
               <p className="card-text">{discription}</p>
               <a href={url} className="btn btn-dark btn-sm">read more</a>
               <p className='card-text'><small className="text-danger">By {author}<br/> published at {date}</small></p>
            </div>
        </div>

       
        


        </>
        
    )
  }
}

export default Newsitem