import React, { Component } from "react";
import Navbar from "./components/Navbar";
import Newscoponent from "./components/Newscoponent";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import NotesContext from "./context/Notes";

export class App extends Component {
  state = {
    progress: 0,
  };

  setProgress = (progress) => {
    this.setState({ progress: progress });
  };
  render() {
    return (
      <>
      <NotesContext.Provider value={{greeeting:'Hello'}}>
        <Router>
          <Navbar />
          <LoadingBar color="#0c0c0cab" progress={this.state.progress} />

          <Switch>
            <Route exact path="/">
              <Newscoponent
                setProgress={this.setProgress}
                key="top"
                category="top"
              />
            </Route>

            <Route exact path="/business">
              <Newscoponent
                setProgress={this.setProgress}
                key="business"
                category="business"
              />
            </Route>

            <Route exact path="/entertainment">
              <Newscoponent
                setProgress={this.setProgress}
                key="entertainment"
                category="entertainment"
              />
            </Route>

            <Route exact path="/health">
              <Newscoponent
                setProgress={this.setProgress}
                key="health"
                category="health"
              />
            </Route>

            <Route exact path="/sports">
              <Newscoponent
                setProgress={this.setProgress}
                key="sports"
                category="sports"
              />
            </Route>

            <Route exact path="/technology">
              <Newscoponent
                setProgress={this.setProgress}
                key="technology"
                category="technology"
              />
            </Route>
          </Switch>
        </Router>
        </NotesContext.Provider>
      </>
    );
  }
}

export default App;
